import React from 'react'
import '../css/Stars.css'

class Stars extends React.Component {
    render() {
        return (
            <div>
                <div id="stars"></div>
                <div id="stars2"></div>
                <div id="stars3"></div>
            </div>
        )
    }
}

export default Stars
